import { createRouter, createWebHistory } from "vue-router";
import Home from "./pages/Home.vue";
import Menu from "./pages/Menu.vue";
import About from "./pages/About.vue";
import fourOhfour from "./pages/fourOhfour.vue";

const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/menu", name: "Menu", component: Menu },
  { path: "/about", name: "About", component: About },
  // Catch-all route for 404 pages
  { path: "/:catchAll(.*)", name: "404", component: fourOhfour },
];

const base = process.env.NODE_ENV === "production" ? "/" : "/";

const router = createRouter({
  history: createWebHistory(base),
  routes,
});

export default router;
