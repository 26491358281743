import axios from "axios";
const API_BASE_URL = "https://easystreetburgers.com/wp-json";

export default {
  async getHeros() {
    try {
      const response = await axios.get(`${API_BASE_URL}/wp/v2/hero`);
      return response.data;
    } catch (error) {
      console.error("Error fetching heros", error);
      throw error;
    }
  },
  async getBurgers() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/burger?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching burgers:", error);
      throw error;
    }
  },
  async getDrinks() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/drink?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching drinks:", error);
      throw error;
    }
  },
  async getFries() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/fry?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Fries:", error);
      throw error;
    }
  },
  async getAddons() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/add-on?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Add-Ons:", error);
      throw error;
    }
  },
  async getMonsters() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/jalapeno-monster?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Jalapeno Monsters:", error);
      throw error;
    }
  },
  async getVeggies() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/veggie?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching Veggie:", error);
      throw error;
    }
  },
  async getHours() {
    try {
      const response = await axios.get(`${API_BASE_URL}/wp/v2/day-of-week`);
      return response.data;
    } catch (error) {
      console.error("Error fetching hours:", error);
      throw error;
    }
  },
  async getAboutUs() {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/wp/v2/about-us?acf_format=standard`
      );
      return response.data;
    } catch (error) {
      console.error("Error fetching about us:", error);
      throw error;
    }
  },
  async getContact() {
    try {
      const response = await axios.get(`${API_BASE_URL}/wp/v2/contact`);
      return response.data;
    } catch (error) {
      console.error("Error fetching contact:", error);
      throw error;
    }
  },
};
