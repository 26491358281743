<template>
  <div class="about-page">
    <div class="about">
      <h1>ʕノ•ᴥ•ʔノ ︵ ┻━┻ <br />404 <br />This page does not exist!</h1>
      <section>
        <p>That link you might have followed is probably out of date. Sorry.</p>
      </section>
      <section class="location">
        <h2 id="location">Location</h2>
        <p class="address">
          4143 Lankershim Blvd<br />
          Studio City, CA 91602
        </p>
        <h2 id="hours">hours</h2>
        <ul class="hours-open">
          <li v-for="day in hours" :key="day.id">
            <span class="day-of-week">{{ day.acf.day_of_week }}&nbsp;</span
            ><span class="hours">&nbsp;{{ day.acf.hours }}</span>
          </li>
        </ul>
      </section>

      <section class="contact">
        <h2 id="contact">Contact</h2>

        <div v-for="contactInfo in contact" :key="contactInfo.id">
          <p class="phone-number">
            <a :href="'tel:' + contactInfo.acf.phone">{{
              contactInfo.acf.phone
            }}</a>
          </p>
          <p class="email">{{ contactInfo.acf.email }}</p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import wpService from "@/services/wpService";
import cache from "@/services/cache";
export default {
  name: "fourOhfour",
  data() {
    return {
      hours: [],
      contact: [],
    };
  },
  async created() {
    if (cache.hoursData) {
      // Use cached data
      this.hours = cache.hoursData;
    } else {
      try {
        this.hours = await wpService.getHours();
        cache.hoursData = this.hours;
      } catch (error) {
        console.error("Error loading hours:", error);
      }
    }
    if (cache.contactData) {
      this.contact = cache.contactData;
    } else {
      try {
        this.contact = await wpService.getContact();
        cache.contactData = this.contact;
      } catch (error) {
        console.error("Error loading contact:", error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
h1 {
  text-align: center;
  font-size: 3em;
}
section {
  p {
    padding: 20px;
  }
}
</style>
