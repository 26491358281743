<template>
  <footer class="footer">
    <div class="container">
      <p>© {{ this.year }} Easy Street Burgers. All rights reserved.</p>
      <div class="social-links">
        <a href="https://www.instagram.com/easystreetburgers" target="_blank"
          ><img
            style="width: 3em; padding: 10px"
            src="../assets/ig.svg"
            alt="instagram icon"
        /></a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "MyFooter",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: #333;
  color: white;
  text-align: center;
  padding: 20px 0;
}

.container {
  max-width: 1200px;
  margin: auto;
  padding: 0 20px;
}

.social-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px;
}
</style>
