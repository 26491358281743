<template>
  <div id="app">
    <MyHeader />

    <main>
      <div class="page-container">
        <KeepAlive>
          <router-view />
        </KeepAlive>
      </div>
    </main>
    <!-- <section class="igFeed">
      <InstagramFeed class="ig" />
    </section> -->
    <MyFooter />
  </div>
</template>

<script>
import MyHeader from "./MyHeader.vue";
import MyFooter from "./MyFooter.vue";
// import InstagramFeed from "../components/IGFeed.vue";
// window.addEventListener("load", function () {
//   var divs = document.querySelectorAll('div[style="display: block;"]');
//   divs.forEach(function (div) {
//     // Check for additional unique characteristics here
//     if (
//       div.querySelector('a[href="https://electricblaze.com/instagram-feed/"]')
//     ) {
//       div.parentNode.removeChild(div);
//     }
//   });
// });
export default {
  name: "AppLayout",
  components: {
    MyHeader,
    MyFooter,
    // InstagramFeed,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:ital,wght@1,300&display=swap");

.cls-1 {
  fill: #ffb921;
}

.cls-1,
.cls-2,
.cls-3 {
  stroke-width: 0px;
}

.cls-3 {
  fill: #b90000;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: "Arial", sans-serif;
}

body {
  font-family: "Poppins", sans-serif;
}
.igFeed {
  background-color: #000;
  padding: 20px;
  @media screen and (max-width: 768px) {
    padding: 0;
  }
  .ig {
    width: 750px;
    margin: auto;
    border-radius: 20px;
    border: 10px solid #fff;
    @media screen and (max-width: 768px) {
      width: 100%;
      border-radius: 0;
      border: 0 solid #fff;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}
h1 {
  background-color: #000;
  color: #fff;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Bebas Neue", sans-serif;
  padding: 5px 0 0;
}
p {
  padding: 0 0 5px;
}
.page-container {
  background-color: #ffb921;
}
</style>
