<template>
  <div class="menu-page">
    <div class="menu">
      <h1>Menu</h1>
      <div class="menu-sections">
        <div
          v-for="(category, key) in filteredCategories"
          class="menu-section"
          :key="key"
        >
          <!-- Special handling for drinks and addons -->
          <div v-if="key === 'drinks'">
            <h2 v-if="menu.addOns.length">AddOns</h2>
            <ul v-if="menu.addOns.length">
              <li v-for="addOn in menu.addOns" :key="addOn.id">
                <div class="menu-item">
                  <h3 class="menu-item-title">{{ addOn.title.rendered }}</h3>
                  <h3 class="menu-item-price">{{ addOn.acf.price }}</h3>
                </div>
                <p class="description">
                  {{ addOn.acf.description ? addOn.acf.description : "" }}
                </p>
              </li>
            </ul>
            <h2 v-if="menu.drinks.length">Drinks</h2>
            <ul v-if="menu.drinks.length">
              <li v-for="drink in menu.drinks" :key="drink.id">
                <div class="menu-item">
                  <h3 class="menu-item-title">{{ drink.title.rendered }}</h3>
                  <h3 class="menu-item-price">{{ drink.acf.price }}</h3>
                </div>
                <p class="description">
                  {{ drink.acf.description ? drink.acf.description : "" }}
                </p>
              </li>
            </ul>
          </div>

          <!-- Regular handling for other categories -->
          <div v-else>
            <h2 :class="key == 'monsters' || key == 'veggies' ? 'green' : ''">
              {{
                key == "monsters"
                  ? "Jalepeno Monster"
                  : key == "veggies"
                  ? "Veggie"
                  : key
              }}
            </h2>
            <ul v-if="category.length">
              <li v-for="item in category" :key="item.id">
                <div class="menu-item">
                  <h3 class="menu-item-title">{{ item.title.rendered }}</h3>
                  <h3 class="menu-item-price">{{ item.acf.price }}</h3>
                </div>
                <p class="description">
                  {{ item.acf.description ? item.acf.description : "" }}
                </p>
              </li>
            </ul>
            <p v-else>Loading menu...</p>
          </div>
        </div>
        <div>
          <img src="../assets/easystreet.svg" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wpService from "@/services/wpService";
import cache from "@/services/cache";

export default {
  name: "MenuPage",
  data() {
    return {
      menu: {
        burgers: [],
        fries: [],
        veggies: [],
        addOns: [],
        drinks: [],
        monsters: [],
      },
    };
  },
  async created() {
    if (cache.menuData) {
      // Use cached data
      this.menu = cache.menuData;
    } else {
      try {
        this.menu.burgers = await wpService.getBurgers();
      } catch (error) {
        console.error("Error loading burgers:", error);
      }

      try {
        this.menu.addOns = await wpService.getAddons();
      } catch (error) {
        console.error("Error loading addOns:", error);
      }

      try {
        this.menu.drinks = await wpService.getDrinks();
      } catch (error) {
        console.error("Error loading drinks:", error);
      }

      try {
        this.menu.fries = await wpService.getFries();
      } catch (error) {
        console.error("Error loading fries:", error);
      }

      try {
        this.menu.monsters = await wpService.getMonsters();
      } catch (error) {
        console.error("Error loading monsters:", error);
      }

      try {
        this.menu.veggies = await wpService.getVeggies();
      } catch (error) {
        console.error("Error loading veggies:", error);
      }
      cache.menuData = this.menu;
    }
  },
  computed: {
    filteredCategories() {
      const filtered = { ...this.menu };
      delete filtered.addOns; // Remove 'addOns' from the filtered categories
      return filtered;
    },
  },
};
</script>

<style lang="scss" scoped>
.green {
  color: green;
}
.menu-page {
  padding: 20px;
  @media screen and (max-width: 768px) {
    padding: 5px;
  }
}
.menu {
  background-color: #fff;
  border: 5px solid #000;
  border-radius: 20px;
  margin: 0 auto;
  padding: 20px;
  font-size: 1.5em;
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    border: none;
    padding: 10px;
    font-size: 1.2em;
  }
  .menu-sections {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
    padding-top: 20px;
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .menu-section {
      position: relative;
      padding: 0;
    }

    .menu-section::after {
      content: "";
      position: absolute;
      right: -5px; /* Half of grid-gap to center the border */
      top: 0;
      bottom: 0;
      width: 1px; /* Border width */
      background: black; /* Border color */
      @media screen and (max-width: 768px) {
        background: none;
      }
    }

    /* Remove border from the last item in each row for 3-column layout */
    .menu-section:nth-child(3n)::after {
      background: none;
    }
  }

  h1,
  h2 {
    text-align: center;
  }
  ul {
    list-style-type: none;
  }
  .menu-section {
    padding: 20px;
  }
  .menu-item {
    display: flex;
    justify-content: space-between;
  }
  .description {
    font-size: 0.8em;
  }
}
</style>
