<template>
  <header class="header">
    <div class="logo">
      <RouterLink to="/"><img src="../assets/easystreet.svg" /></RouterLink>
    </div>
    <button
      class="hamburger-menu"
      :class="{ open: isMenuOpen }"
      @click="toggleMenu"
    >
      <span></span>
      <span></span>
      <span></span>
    </button>
    <nav class="navbar" :class="{ open: isMenuOpen }">
      <ul class="nav-list">
        <li class="nav-item" v-for="link in navLinks" :key="link.text">
          <RouterLink
            class="nav-link"
            :to="link.url"
            @click="handleNavigation(link)"
          >
            {{ link.text }}
          </RouterLink>
        </li>
      </ul>
    </nav>
  </header>
</template>

<script>
export default {
  name: "MyHeader",
  data() {
    return {
      isMenuOpen: false,
      navLinks: [
        { text: "Menu", url: "/menu" },
        { text: "About", url: "/about" },
        { text: "Contact", url: "/#contact" },
        { text: "Location", url: "/#location" },
      ],
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
    handleNavigation(link) {
      // Toggle menu
      this.toggleMenu();

      if (link.url.startsWith("/#")) {
        // Prevent default RouterLink behavior and handle smooth scroll
        this.scrollToSection(link.url.substring(2)); // Remove '/#' and get the id
      }
      // For other links, the RouterLink will handle the navigation
    },
    scrollToSection(sectionId) {
      this.$nextTick(() => {
        const element = document.getElementById(sectionId);
        if (element) {
          const elementPosition = element.getBoundingClientRect().top;
          const offsetPosition =
            elementPosition + window.pageYOffset - this.headerHeight;

          window.scrollTo({
            top: offsetPosition,
            behavior: "smooth",
          });
        }
      });
    },
  },
  mounted() {
    const hash = window.location.hash;
    if (hash && hash.startsWith("#")) {
      this.scrollToSection(hash.substring(1));
    }
  },
  computed: {
    headerHeight() {
      const header = document.querySelector(".header");
      return header ? header.offsetHeight : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background-color: #ffffff;
  font-family: "Bebas Neue", sans-serif;
  color: #000;
  font-size: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  .logo {
    width: 120px;
    z-index: 2000;
  }
  .navbar {
    display: block;
    padding: 0 20px;
    position: relative;
    .nav-list {
      list-style: none;
      display: flex;
      margin: 0;
      padding: 0;
      .nav-item {
        margin: 0 10px;
        .nav-link {
          color: #000;
          text-decoration: none;
        }
      }
    }
    @media (max-width: 768px) {
      text-align: center;
      position: fixed;
      left: 100%;
      font-size: 1.5em;
      line-height: 2em;
      &.open {
        left: 0;
        width: 100%;
        height: 100vh;
        top: 125px;
        background-color: #fff;
        transition: all 0.5s;
        .nav-list {
          flex-direction: column;
        }
      }
    }
  }
}

// Hamburger Menu Styles
.hamburger-menu {
  display: none;
  cursor: pointer;
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  z-index: 2000;
  &.open {
    span {
      transition: transform 0.3s 0.15s, top 0.3s 0s;
    }
    span:nth-child(1) {
      transform: rotateZ(45deg);
      top: 8px;
    }
    span:nth-child(2) {
      opacity: 0;
    }
    span:nth-child(3) {
      transform: rotateZ(-45deg);
      top: -8px;
    }
  }
  span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #000;
    transition: transform 0.3s 0s, top 0.3s 0.15s;
    position: relative;
    top: 0;
  }
  @media (max-width: 768px) {
    display: flex;
  }
}
</style>
