<template>
  <div class="about-page">
    <div class="about">
      <h1>About Us</h1>
      <section v-for="section in aboutUs" :key="section.id">
        <div
          v-if="section.acf.image"
          class="img-container"
          :style="`background-image: url(${section.acf.image})`"
          style="
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          "
        ></div>
        <div>
          <h2>{{ section.title.rendered }}</h2>
          <p v-html="section.acf.description"></p>
        </div>
        <div
          v-if="section.acf.image2"
          class="img-container"
          :style="`background-image: url(${section.acf.image2})`"
          style="
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          "
        ></div>
      </section>
    </div>
  </div>
</template>

<script>
import wpService from "@/services/wpService";
import cache from "@/services/cache";
export default {
  name: "AboutPage",
  data() {
    return {
      aboutUs: [],
    };
  },
  async created() {
    if (cache.aboutData) {
      // Use cached data
      this.aboutUs = cache.aboutData;
    } else {
      try {
        this.aboutUs = await wpService.getAboutUs();
        cache.aboutData = this.aboutUs;
      } catch (error) {
        console.error("Error loading About-Us:", error);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.about-page {
  padding: 0;
}
.about {
  background-color: #fff;
  margin: 0 auto;
  padding: 0;
  text-align: left;
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
  h1 {
    text-align: center;
    font-size: 3em;
  }
  section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    align-items: start;
    background-color: bisque;
    padding: 20px;
    transition: all 0.2s ease;
    @media screen and (max-width: 1080px) {
      grid-template-columns: 1fr 3fr 1fr;
    }
    @media screen and (max-width: 768px) {
      grid-template-columns: 1fr;
    }
    .img-container {
      width: 100%;
      height: 100%;
      min-height: 100px;
    }
    img {
      width: 100%;
      object-fit: cover;
      padding: 20px;
    }
    p {
      padding: 50px;
      font-size: 1em;
      @media screen and (max-width: 768px) {
        padding: 20px;
      }
    }
    h2 {
      text-align: center;
      font-size: 2em;
    }
  }

  .team-members {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 20px;
  }

  .team-member {
    width: 150px;
    text-align: center;

    img {
      width: 100%;
      border-radius: 50%;
    }
  }
}
</style>
